<template>
    <div class="auth-wrapper auth-v2">
        <b-row class="auth-inner m-0">
            <!-- Brand logo-->
            <b-link class="brand-logo">
                <img src="@/assets/images/logo/logo.svg" class="apro_logo" />
            </b-link>
            <!-- /Brand logo-->

            <!-- Tool bar -->
            <b-link class="tool-bar">
                <dark-toggler class="d-block float-right" />
                <Locale class="d-block float-right" />
            </b-link>
            <!-- Tool bar -->

            <!-- Left Text-->
            <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
                <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
                    <b-img :src="imgUrl" fluid alt="" />
                </div>
            </b-col>
            <!-- /Left Text-->

            <!-- Reset password-->
            <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
                <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
                    <b-card-title title-tag="h2" class="font-weight-bold mb-1">
                        {{ $t('messages.reset_password') }}
                    </b-card-title>
                    <b-card-text class="mb-2">
                        {{ form.email }}
                    </b-card-text>

                    <!-- form -->
                    <validation-observer ref="simpleRules">
                        <b-form class="auth-reset-password-form mt-2" method="POST" @submit.prevent>
                            <!-- password -->
                            <validation-input
                                id="reset-password-new"
                                v-model="form.password"
                                field="password"
                                rules="required|password_strength"
                                :label="$t('fields.new_password')"
                                :type="password1FieldType"
                                placeholder="············"
                            >
                                <template #append>
                                    <b-input-group-append is-text>
                                        <feather-icon
                                            :icon="password1ToggleIcon"
                                            class="cursor-pointer"
                                            @click="togglePassword1Visibility"
                                        />
                                    </b-input-group-append>
                                </template>
                            </validation-input>
                            <!-- confirm password -->
                            <validation-input
                                id="reset-password-confirm"
                                v-model="form.password_confirmation"
                                field="confirm_password"
                                rules="required|confirmed:password|password_strength"
                                :label="$t('fields.confirm_password')"
                                :type="password2FieldType"
                                placeholder="············"
                            >
                                <template #append>
                                    <b-input-group-append is-text>
                                        <feather-icon
                                            :icon="password2ToggleIcon"
                                            class="cursor-pointer"
                                            @click="togglePassword2Visibility"
                                        />
                                    </b-input-group-append>
                                </template>
                            </validation-input>

                            <!-- captcha -->
                            <captcha ref="captcha" />

                            <!-- submit button -->
                            <b-button
                                v-ripple.500="'rgba(255, 255, 255, 0.15)'"
                                block
                                type="submit"
                                variant="primary"
                                @click="validationForm"
                            >
                                {{ $t('fields.set_new_password') }}
                            </b-button>
                        </b-form>
                    </validation-observer>

                    <p class="text-center mt-2">
                        <b-link :to="{ name: 'login' }">
                            <feather-icon icon="ChevronLeftIcon" /> {{ $t('messages.back_to_login') }}
                        </b-link>
                    </p>
                </b-col>
            </b-col>
            <!-- /Reset password-->
        </b-row>
    </div>
</template>

<script>
/* eslint-disable global-require */
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import Locale from '@core/layouts/components/app-navbar/components/Locale.vue'
import Captcha from '@/components/Captcha'
import ValidationInput from '@/components/ValidationInput'
import auth from '@/api/auth'

export default {
    components: {
        Locale,
        DarkToggler,
        Captcha,
        ValidationInput,
    },
    data() {
        return {
            form: {
                token: '',
                email: '',
                password: '',
                password_confirmation: '',
            },

            // Toggle Password
            password1FieldType: 'password',
            password2FieldType: 'password',
        }
    },
    computed: {
        passwordToggleIcon() {
            return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
        },
        imgUrl() {
            let image = require('@/assets/images/pages/reset-password-v2.svg')

            if (store.state.appConfig.layout.skin === 'dark') {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                image = require('@/assets/images/pages/reset-password-v2-dark.svg')

                return image
            }

            return image
        },
        password1ToggleIcon() {
            return this.password1FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
        },
        password2ToggleIcon() {
            return this.password2FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
        },
    },
    mounted() {
        this.form.email = this.$route.query.email
        this.form.token = this.$route.query.token

        if (!this.form.token || !this.form.email) {
            this.$router.push('/login')
        }
    },
    methods: {
        togglePassword1Visibility() {
            this.password1FieldType = this.password1FieldType === 'password' ? 'text' : 'password'
        },
        togglePassword2Visibility() {
            this.password2FieldType = this.password2FieldType === 'password' ? 'text' : 'password'
        },
        validationForm() {
            this.$refs.simpleRules.validate().then((success) => {
                if (success) {
                    this.$refs.captcha.execute(this.handleSubmit)
                }
            })
        },
        async handleSubmit(captcha_response) {
            try {
                const params = { ...this.form, captcha_response }
                const response = await auth.resetPassword(params)

                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: response.data.message,
                        icon: 'EditIcon',
                        variant: 'success',
                    },
                })

                setTimeout(() => {
                    this.$router.push({ name: 'login' })
                }, 2000)
            } catch (error) {
                //
            } finally {
                //
            }
        },
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
